import { _ } from "vue-underscore";

export default {
  data: () => ({
    content: "",
    backUrl: "/campaign-template",
    campaignTemplate: {
      id: 0,
      name: "",
      subject: "",
      description: "",
      body: "",
    },
    config: {},
  }),

  computed: {},

  methods: {
    validatecampaignTemplate() {
      this.campaignTemplate.id > 0 ? this.update() : this.add();
    },
    update() {
      let _vm = this;
      this.axios
        .post("/campaign-templates/" + this.campaignTemplate.id, {
          ...this.campaignTemplate,
          _method: "PATCH",
        })
        .then(function (response) {
          _vm.campaignTemplate = response.data.data;
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },
    add() {
      let _vm = this;
      this.axios
        .post("/campaign-templates", _vm.campaignTemplate)
        .then(function (response) {
          _vm.$router.push(_vm.backUrl);
        })
        .catch(function () {});
    },

    getDetail() {
      let _vm = this;
      this.axios
        .get("/campaign-templates/" + this.$route.params.id)
        .then(function (response) {
          _vm.campaignTemplate = response.data.data;
        })
        .catch(function () {});
    },
  },
  mounted() {
    if (this.$route.params.id > 0) {
      this.getDetail();
    }
  },
};
